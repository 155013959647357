<template>
    <div class="virtual-tree-wrapper"
        v-loading="loadingFll"
        element-loading-text="正在处理中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 0.8)"
        >
        <div class="ready-choose" >
            <div class="title">
                选择
            </div>
            <el-input placeholder="输入关键字进行过滤" v-model="filterText">
            </el-input>
            <el-checkbox
                v-if="selTreeOpt.status === 'multiple' && !selTreeOpt.checkStrictly && filterText === ''"
                v-model="checkAll"
                :indeterminate="isIndeterminate"
                class="check-all"
                @change="handleCheckAllChange(checkAll)"
            >全选</el-checkbox>
            <vue-easy-tree
                ref="tree"
                show-checkbox
                node-key="id"
                height="365px"
                :data="selTreeOpt.data"
                :check-strictly="selTreeOpt.checkStrictly"
                :props="defaultProps"
                :filter-node-method="filterNode"
                :empty-text="selTreeOpt.empty"
                :default-checked-keys="selTreeOpt.showData"
                @check="handleClick"
                @node-click="nodeClick"
                >
            </vue-easy-tree>
        </div>
        <div class="already-selected">
            <div class="title">
                已选
            </div>
            <div class="wrapper-line">
                <div
                    v-for="(item, index) of selList"
                    :key="index"
                    class="sel-name"
                >
                    <span class="name">{{ item.name }}</span>
                    <span class="sel-del el-icon-circle-close" @click="del(item, index)"></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import VueEasyTree from "@wchbrad/vue-easy-tree";
    import "@wchbrad/vue-easy-tree/src/assets/index.scss"
    export default {
        name: "VirtualTree",
        components: {
            VueEasyTree
        },
        props: {
            selTreeOpt: {
                type: Object
            }
        },
        data() {
            return {
                filterText: '',
                editChecked: '',
                treeData: [],
                selList: [],
                handleClickStatus: true,
                checkAll: false,
                isIndeterminate: false,
                defaultProps: {
                    children: 'children',
                    label: 'name'
                },
                loadingFll: true,
            }
        },
        created() {
            setTimeout(() => {
                this.init()
            }, 100)
        },
        methods: {
            init() {
                this.$nextTick(() => {
                    this.filterText = '';
                    console.log(this.selTreeOpt.showData, 'this.selTreeOpt.showData1234')
                    if (!this.selTreeOpt.checkStrictly) {
                        this.checkStrictlyClick()
                    } else {
                        this.treeData = this.$refs.tree.getCheckedNodes()
                        this.selList = []
                    }
                    setTimeout(() => {
                        this.loadingFll = false;
                    })
                })
            },
            nodeClick(data, node, v) {
                if (this.selTreeOpt.checkStrictly) {
                    this.$refs.tree.setCheckedNodes([data]);
                }
            },
            handleClick(data, checked, node) {
                this.loadingFll = true;
                setTimeout(() => {
                    this.handlerClickItem(data, checked, node)
                })
            },
            handlerClickItem(data, checked, node) {
                if (this.selTreeOpt.checkStrictly) {
                    if (this.selTreeOpt.status == 'single') {
                        if (checked) {
                            this.$refs.tree.setCheckedNodes([data]);
                            this.selList.splice(0, 1);
                            this.selList.push(data);
                            this.$emit('setTreeData', { data: data, key: this.selTreeOpt.key, id: this.selTreeOpt.id, typeId: this.selTreeOpt.typeId, index: this.selTreeOpt.index })
                        } else {
                            this.selList.forEach((item, index) => {
                                if (item.id == data.id) {
                                    this.selList.splice(index, 1);
                                }
                            })
                            if (this.selList.length == 0) {
                                this.$emit('setTreeData', { data: {}, key: this.selTreeOpt.key })
                            }
                        }
                    } else {
                        if (!this.handleClickStatus) return;
                        // 多选
                        // 是否限制最大数量
                        if (this.selTreeOpt.maxLen && this.treeData.length >= Number(this.selTreeOpt.maxLen) && checked) {
                            this.$message.error(`最多选择${this.selTreeOpt.maxLen}个,如想选择请删除`);
                            this.setNewSel()
                            return;
                        }
                        let contains = false,
                            index = 0;
                        this.treeData.forEach((item, idx) => {
                            if (item.id == data.id) {
                                contains = true;
                                index = idx;
                            }
                        });
                        contains ? this.treeData.splice(index, 1) : this.treeData.push(data);
                        this.selList = JSON.parse(JSON.stringify(this.treeData));
                        this.$emit('setTreeData', { data: this.treeData, key: this.selTreeOpt.key, selList: this.treeData.map(i => i.name), id: this.selTreeOpt.id, typeId: this.selTreeOpt.typeId, index: this.selTreeOpt.index });
                        }
                    } else {
                        this.checkStrictlyClick()
                    }
                    setTimeout(() => {
                        this.loadingFll = false;
                    })
            },
            setNewSel() {
                this.loadingFll = true;
                setTimeout(() => {
                    this.getNewSelType()
                }, 200)
            },
            getNewSelType() {
                this.handleClickStatus = false;
                this.$nextTick(() => {
                    this.$refs.tree.setCheckedNodes(this.selList);
                });

                setTimeout(() => {
                    this.handleClickStatus = true;
                }, 20)

                this.loadingFll = false;
            },
            filterNode(value, data) {
                if (!value) return true;
                return data.name.indexOf(value) !== -1;
            },
            handleCheckAllChange(data) {
                this.loadingFll = true
                setTimeout(() => {
                    this.allCheckSel(data)
                }, 200)
            },
            allCheckSel (data) {
                if (data) {
                    // 全选
                    this.$refs.tree.setCheckedNodes(this.selTreeOpt.data)
                } else {
                    // 清空
                   this.$refs.tree.setCheckedNodes([])
                }
                this.checkStrictlyClick()
                // 修改全选框状态
                this.changeIsIndeterminate()
                setTimeout(() => {
                    this.loadingFll = false;
                })
            },
            checkStrictlyClick() {
                this.treeData = this.$refs.tree.getCheckedNodes()
                let halfCheckedNodes = this.$refs.tree.getHalfCheckedNodes()
                this.selList = []
                this.treeData.forEach((item) => {
                    if (item.key !== 'organ' && halfCheckedNodes.find(i => i.id === item.parentOrg)) {
                        this.selList.push(item)
                    }
                    if (item.key === 'organ' && !this.treeData.find(i => i.id === item.parentOrg)) {
                        this.selList.push(item)
                    }
                })
                this.$emit('setTreeData', { data: this.treeData, selList: this.selList.map(i => i.name), key: this.selTreeOpt.key, id: this.selTreeOpt.id, typeId: this.selTreeOpt.typeId, index: this.selTreeOpt.index });
            },
            changeIsIndeterminate() {
                if (this.selTreeOpt.data.length === this.selList.length) {
                    this.checkAll = true
                } else {
                    if (this.selList.length === 0) {
                        this.checkAll = false
                        this.isIndeterminate = false
                    } else {
                        this.isIndeterminate = true
                    }
                }
            },
            resetChecked() {
                this.$refs.tree.setCheckedKeys([]);
                if (!this.selTreeOpt.checkStrictly) {
                    this.checkStrictlyClick()
                }
                this.filterText = ''
            },
            del(data, index) {
                this.loadingFll = true;
                setTimeout(() => {
                    this.delItem(data, index)
                },100)

            },
            delItem (data, index) {
                this.$nextTick(() => {
                    if (this.selTreeOpt.status == 'single') {
                        this.selList = [];
                        this.$refs.tree.setCheckedNodes([data.id]);
                        this.$emit('setTreeData', { data: {}, key: this.selTreeOpt.key, id: this.selTreeOpt.id, typeId: this.selTreeOpt.typeId, index: this.selTreeOpt.index })
                    } else {
                        this.handleClickStatus = false;
                        this.selList.splice(index, 1);
                        this.treeData = this.$refs.tree.getCheckedNodes();
                        console.log(this.treeData, this.selList, 'this.treeData---')
                        this.$emit('setTreeData', { data: this.selList, selList: this.selList.map(i => i.name), key: this.selTreeOpt.key, id: this.selTreeOpt.id, typeId: this.selTreeOpt.typeId, index: this.selTreeOpt.index });
                        this.setNewSel();
                        setTimeout(() => {
                            this.handleClickStatus = true;
                        }, 20)
                        setTimeout(() => {
                            this.loadingFll = false;
                        })
                    }
                    this.changeIsIndeterminate()

                })
            },
            /*
                全选
            */
            setCheckedNodes() {
                this.$refs.tree.setCheckedNodes(this.selTreeOpt.data)
            },
            //
        },
        watch: {
            'selTreeOpt.showData': {
                handler() {
                    setTimeout(() => {
                        this.init()
                    }, 100)
                },
                deep: true
            },
            filterText(val) {
                this.$refs.tree.filter(val);
            }
        },
    }
</script>
<style lang="scss" scoped>
    .virtual-tree-wrapper {
        height: 455px;
        overflow: auto;
        display: flex;
        .ready-choose {
            width: 48%;
            height: 405px;
        }
        .already-selected {
            height: 405px;
            .wrapper-line {
                height: 405px;
                overflow: auto;

                .check-all {
                    margin-left: 24px;
                }
                .sel-name {
                    height: 30px;
                    line-height: 30px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    box-sizing: border-box;
                    padding: 0 40px;
                    .name {
                        width: 100%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .sel-del {
                        font-size: 16px;
                        cursor: pointer;
                    }
                }
            }
        }
        .title {
            height: 34px;
            line-height: 34px;
            font-size: 16px;
        }
        // .ready-choose {
        //     float: left;
        // }
    }
</style>
