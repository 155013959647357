<template>
    <div class="table-filter" style="z-index: 30001">
        <div class="general-wrapper">
            <p class="title">
                <span>表格显示设置</span>
                <i class="el-icon-close close-icon" @click="handlerClose"></i>
            </p>
            <div class="display-density">
                <div class="display-density-label">显示密度</div>
                <div class="display-density-options">
                    <div
                        class="display-density-option"
                        :class="{ 'display-density-option_active' : density === '1' }"
                        @click="handlerDensityChange('1')">
                        <img class="display-density-option-img" :src="imgSparse" alt="sparse" srcset="">
                        <img class="display-density-option-active-icon" :src="imgDensityActive" alt="active">
                    </div>
                    <div
                        class="display-density-option"
                        :class="{ 'display-density-option_active' : density === '2' }"
                        @click="handlerDensityChange('2')">
                        <img class="display-density-option-img" :src="imgDense" alt="dense" srcset="">
                        <img class="display-density-option-active-icon" :src="imgDensityActive" alt="active">
                    </div>
                </div>
            </div>
            <div class="reset-wrapper">
                <span class="header">表头设置</span>
                <span class="default" @click="handlerReset">恢复默认</span>
            </div>
            <!-- <div class="general-content">
                <div
                    v-for="(item, index) in generalList"
                    :key="index"
                    class="general-item">
                    <span class="label">{{ item.conditionName }}</span>
                    <el-switch
                        :width="27"
                        v-model="item.isOff"
                        active-value="1"
                        active-color="#3C7FFF"
                        inactive-value="0"
                        inactive-color="#C5CDDE"
                    ></el-switch>
                </div>
            </div> -->
            <div class="des">
                <span class="content">长按文字拖动排序</span>
            </div>
            <div class="dragging-wrapper">
                <draggable
                    :list="draggingList"
                    :animation="340"
                    group="draggingList"
                    @update="handlerSort">
                    <transition-group class="draggble">
                        <div
                            v-for="(item, index) in draggingList"
                            :key="index"
                            class="dragging-item">
                            <div class="header-label-div">
                                <img class="header-icon" :src="imgSort" alt="sort" srcset="">
                                <span class="header-label"> {{ item.conditionName }}</span>
                            </div>
                            <el-switch
                                :width="27"
                                v-model="item.isOff"
                                active-value="1"
                                active-color="#3C7FFF"
                                inactive-value="0"
                                inactive-color="#C5CDDE"
                                :disabled="disabledList.includes(item.conditionId)"
                            ></el-switch>
                        </div>
                    </transition-group>
                </draggable>
            </div>
        </div>
        <div class="edit-wrapper"></div>
    </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
    name: "TableFilter",
    components: {
        draggable,
    },
    props: {
        density: {
            type: String,
            default: '1'
        },
        draggingList: {
            type: Array,
            default() {
                return [];
            },
        },
        disabledList: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    data() {
        return {
            generalList: [],
        };
    },
    computed: {
        imgSparse() {
            return require("@/assets/images/tableSetting/sparse.png");
        },
        imgDense() {
            return require("@/assets/images/tableSetting/dense.png");
        },
        imgDensityActive() {
            return require("@/assets/images/tableSetting/density-active.png");
        },
        imgSort() {
            return require("@/assets/images/tableSetting/sort.png");
        }
    },
    methods: {
        handlerDensityChange(density) {
            this.$emit('densityChange', density)
        },
        handlerSort() {
            this.$emit('sort');
        },
        handlerReset() {
            this.$emit('reset');
        },
        handlerClose() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
.table-filter {
    .general-wrapper {
        box-sizing: border-box;
        padding: 23px;
        .title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            padding-bottom: 28px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            .close-icon {
                color: #999999;
                font-size: 20px;
                cursor: pointer;
            }
        }

        .display-density {
            display: flex;
            flex-direction: row;
            align-items: flex-start;

            .display-density-label {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
            }

            .display-density-options {
                margin-left: auto;
                display: flex;
                flex-direction: row;

                .display-density-option {
                    position: relative;
                    width: 94px;
                    height: 52px;
                    background: #FFFFFF;
                    border-width: 1px ;
                    border-style: solid;
                    border-color: #E6E6E6;
                    box-sizing: border-box;
                    border-radius: 8px;
                    cursor: pointer;

                    .display-density-option-img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        display: block;
                    }

                    .display-density-option-active-icon {
                        display: none;
                        position: absolute;
                        width: 20px;
                        height: 20px;
                        left: 0;
                        bottom: 0;
                    }
                }
                .display-density-option + .display-density-option {
                    margin-left: 8px;
                }

                .display-density-option_active {
                    border-color: #3C7FFF;

                    .display-density-option-active-icon {
                        display: block;
                    }
                }
            }
        }

        .reset-wrapper {
            display: flex;
            justify-content: space-between;
            margin-top: 30px;
            .header {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
            }
            .default {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #3c7fff;
                cursor: pointer;
            }
        }

        .general-content {
            margin-top: 6px;
            .general-item {
                height: 36px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .label {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                }
                ::v-deep .el-switch {
                    .el-switch__core {
                        height: 16px;
                    }
                    .el-switch__core:after {
                        width: 10px !important;
                        height: 10px !important;
                        top: 2px !important;
                        left: 2px;
                    }
                }
                ::v-deep .el-switch.is-checked {
                    .el-switch__core:after {
                        left: 30px;
                    }
                }
            }
        }
    }
    .des {
        margin-top: 16px;
        .content {
            display: block;
            width: 100%;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            line-height: 36px;

            text-align: center;
            position: relative;
            height: 30px;
            line-height: 30px;
        }

        .content:after,.content:before{
            content: "";
            position: absolute;
            top: 50%;
            background: #C7C7C7;
            height: 1px;
            width: 26%;
        }
        .content:after{
            left: 0;
        }
        .content:before{
            right: 0;
        }
    }
    .dragging-wrapper {
        .dragging-item {
            height: 36px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            .header-label-div {
                display: flex;
                align-items: center;
                .header-icon {
                    width: 6px;
                    height: 10px;
                    display: block;
                }
                .header-label {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    margin-left: 8px;
                }
            }
            ::v-deep .el-switch {
                .el-switch__core {
                    height: 16px;
                }
                .el-switch__core:after {
                    width: 10px !important;
                    height: 10px !important;
                    top: 2px !important;
                    left: 2px;
                }
            }
            ::v-deep .el-switch.is-checked {
                .el-switch__core:after {
                    left: 30px;
                }
            }
        }
    }
}
</style>
